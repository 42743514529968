import dynamic from "next/dynamic";
import Head from "next/head";

import { datadogRum } from "@datadog/browser-rum";

if (process.env.NEXT_PUBLIC_ENV === "stage")
  datadogRum.init({
    applicationId: "21a4ffc3-18d0-4d35-9e4a-9477c0aff124",
    clientToken: "pub8f8578d69d8cc289b035f054c6f676df",
    site: "us5.datadoghq.com",
    service: "shaadi-vines-cdn",
    env: "stage",
    // Specify a version number to identify the deployed version of your application in Datadog
    version: "1.0.3",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "allow",
  });
else if (process.env.NEXT_PUBLIC_ENV === "prod")
  datadogRum.init({
    applicationId: "5e5a6eba-0584-4404-a516-1b3c65c47ddb",
    clientToken: "pub20aadba92b3233d9ea46c6d65a64d97f",
    site: "us5.datadoghq.com",
    service: "shaadi-vines-cdn-prod",
    env: "prod",
    // Specify a version number to identify the deployed version of your application in Datadog
    version: "1.0.3",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "allow",
  });

const Loader = dynamic(() => import("@/Components/common/loader-sv"), {
  ssr: false,
});

const Notification = dynamic(
  () => import("@/Components/common/notification-sv"),
  {
    ssr: false,
  }
);

import FontFaceObserver from "fontfaceobserver";

import "@/styles/globals.css";
import { Toaster } from "@/Components/ui/toaster";
import { useEffect } from "react";

export default function App({ Component, pageProps }) {
  useEffect(() => {
    const fonts = [
      "EuphoriaScript",
      "AlexBrush",
      "AnastasiaScript",
      "BigdeyDemo",
      "Daydream",
      "GreatVibes",
      "Lovely",
      "Lovelyn",
      "PinyonScript",
      "RumbleBrave",
      "AmsterdamFour",
      "Amsterdam",
    ];

    const fontPromises = fonts.map((font) => new FontFaceObserver(font).load());

    Promise.all(fontPromises)
      .then(() => {
        console.log("All fonts have loaded");
        // Here you can set a state to indicate fonts are ready
        // Or initialize your Fabric.js canvas
      })
      .catch((err) => {
        console.error("Some fonts did not load", err);
      });
  }, []);
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>

      <Loader />
      <Notification />
      <Toaster />
      <main>
        <Component {...pageProps} />
      </main>
    </>
  );
}
